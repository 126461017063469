import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import AakdEvent from "../../components/event/aakd";
import SponsorTable from "../../components/contact/sponsor-table";

const Sponsor = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | Sponsors">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="Sponsorship" crumbTitle="Sponsorship" />
      <Container className="about-headertext pb-40">
        <Row>
          Sponsors make the work we do at MYLTF possible. If you're interested
          in becoming a sponsor, contact us with your preferred sponsor level:
        </Row>
      </Container>
      <SponsorTable />
      <Container className="about-headertext pb-40">
        <Row>
          <h4>Sponsorship may be provided in monetary donations or products</h4>
          <p>
            If your organization is interested in only sponsoring one event,
            please contact Eric Jackson at 404-918-6010 or eric@tennisatl.org
          </p>{" "}
        </Row>
      </Container>
    </div>
    <Footer />
  </Layout>
);

export default Sponsor;
