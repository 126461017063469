import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import MissionContent from "../../components/team/mission-content";
import SponsorsBlock from "../../components/sponsors-block";
import MyltFaq from "../../components/mylt-faq";

const SponsorTable = () => (
  <Container>
    {/* <Row>
      <Col lg="12"> */}
        <table class="sponsor-table">
          <thead>
            <tr>
              <th>Sponsor Benefits</th>
              <th>Co-Host Title Series Sponsor $10,000</th>
              <th>Platinum Series Sponsor $5,000</th>
              <th>Bronze Individual Event Sponsor $2,000</th>
              <th>Supporting In-Kind Sponsor $1,000</th>
            </tr>
            <tr>
              <th>Sponsor Benefits</th>
              <th>
                *Exclusive Level Sponsorship offers the highest degree of
                promotional exposure. Only four sponsorships available at this
                level.
              </th>
              <th>
                *Second Level Sponsorship is featured below the Co-Host Title
                Series level in descending size & order.
              </th>
              <th>
                *Third Level Outstanding mid-level value. Sponsorship descends
                in size & order from previous level.
              </th>
              <th>*Fourth Level Sponsorship offers Event Day Exposure.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                *Print Advertising Features your logo on print materials
                (brochures/flyers) distributed throughout the MYLTF, Inc.
              </td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
              <td></td>
            </tr>
            <tr>
              <td>
                *Press Releases Features your business on all distributions
              </td>
              <td>✔️</td>
              <td>✔️</td>
              <td>Organization Name Listed Only</td>
              <td></td>
            </tr>
            <tr>
              <td>*Website Features your logo on the MYLTF, Inc. website</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>Organization Name Listed Only</td>
              <td></td>
            </tr>
            <tr>
              <td>*Event T-shirts Features your logo on event t-shirt</td>
              <td>Logo Exclusivity</td>
              <td>✔️</td>
              <td>Organization Name Listed Only</td>
              <td></td>
            </tr>
            <tr>
              <td>
                *Event Novelty Items Features your logo on event novelty items
                (pens, sanitizer key chains, reusable bags, etc.)
              </td>
              <td>✔️</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>*Event Banner Features your logo on Event Day Podium</td>
              <td>✔️</td>
              <td>✔️</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                *Participant Bags Features organization’s designated giveaway
                item(s) in participant bags.
              </td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
          </tbody>
        </table>
      {/* </Col>
    </Row> */}
  </Container>
);

export default SponsorTable;
