import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col } from "react-bootstrap";
import serviceBg from "../assets/images/backgrounds/service-hand-bg-1-1.png";
import serviceLine from "../assets/images/shapes/service-line-1-1.png";
import heart from "../assets/images/shapes/Green-ball.png";

// sponsors
import mAgnesLogo from "../assets/images/sponsors/M_Agnes_Jones_Elementary_Logo.png";

const sponsorData = [
  {
    image: mAgnesLogo,
    alt: "M. Agnes Jones Elementary School",
  },
];

const SponsorsBlock = ({ extraClass }) => {
  const brandCarouselOptions = {
    spaceBetween: 100,
    slidesPerView: 5,
    autoplay: { delay: 5000 },
    breakpoints: {
      0: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
      375: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
      575: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
      767: {
        spaceBetween: 50,
        slidesPerView: 4,
      },
      991: {
        spaceBetween: 50,
        slidesPerView: 5,
      },
      1199: {
        spaceBetween: 100,
        slidesPerView: 5,
      },
    },
  };

  return (
    <section
      className="service-one pb-90"
      style={{ backgroundImage: `url(${serviceBg})` }}
    >
      <Container className="pt-120">
        <img src={serviceLine} alt="" className="service-one__shape-1" />
        <div className="block-title">
          <p>
            <img src={heart} width="15" alt="" />
            Our Partners
          </p>
          <h3>
            Sponsorships and Partners <br /> make the work we do possible.
          </h3>
        </div>
        <Row>
          <div className={`client-carousel pb-120  ${extraClass}`}>
            <Container>
              <Swiper {...brandCarouselOptions}>
                <div className="swiper-wrapper">
                  {sponsorData.map(({ image, alt }) => (
                    <SwiperSlide>
                      <img src={image} alt={alt} />
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </Container>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default SponsorsBlock;
