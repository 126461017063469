import React, { useContext } from "react";
import { Accordion, Container, Row, Col, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import heart from "../assets/images/shapes/Green-ball.png";
import heart1 from "../assets/images/shapes/about-count-heart-1-1.png";
import faqImage from "../assets/images/myltf/IMG_9679.jpg";

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <h2
      className="para-title"
      style={{ color: `${isCurrentEventKey ? "var(--thm-secondary)" : ""}` }}
    >
      <span
        role="button"
        tabIndex={0}
        onClick={decoratedOnClick}
        onKeyDown={decoratedOnClick}
      >
        <i
          style={{
            color: `${isCurrentEventKey ? "var(--thm-secondary)" : ""}`,
          }}
          className={`far ${isCurrentEventKey ? "fa-minus" : "fa-plus"}`}
        ></i>
        {children}
      </span>
    </h2>
  );
};
const MyltFaq = () => {
  return (
    <section className="faq-one pt-120 pb-60">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="faq-one__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Why MTLTF
                </p>
                <h3>Our Methods and Philosophy</h3>
              </div>

              <Accordion
                as="ul"
                id="accordion"
                defaultActiveKey="1"
                className="list-unstyled"
              >
                <Card as="li">
                  <ContextAwareToggle eventKey="0">
                    Why MYLT?
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="0">
                    <p>
                      Made You Love Tennis is a community-oriented program that
                      impacts youth by introducing tennis as a means of physical
                      activity, social engagement, and personal growth. Since
                      the beginning, the prime focus of this organization has
                      been the kids. The mission of MYLT is to enrich the
                      self-esteem, self-awareness, and self-confidence of people
                      of all ages, backgrounds, disabilities, and skill levels,
                      contributing to our methodology, philosophy, and social
                      connections in the community. MYLT genuinely believes that
                      by providing an open-arms organization to underserved
                      youth and under-resourced communities, there will be a
                      gradual increase in accessibility, promoting healthy
                      lifestyles, strengthening community involvement, and
                      decreasing the number of kids falling into the wrong
                      crowd.
                    </p>
                  </Accordion.Collapse>
                </Card>
                <Card as="li">
                  <ContextAwareToggle eventKey="1">
                    MYLT Methodology
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="1">
                    <p>
                      Made You Love Tennis Methodology is holistic tennis that
                      focuses on developing the player as a whole—physically,
                      mentally, and emotionally. This holistic approach aims to
                      create well-rounded individuals who carry the values and
                      principles learned in the sport daily. By nurturing
                      physical, mental, and emotional aspects, this approach
                      sets the stage for youth to reach their full potential and
                      thrive on and off the tennis court.
                    </p>
                  </Accordion.Collapse>
                </Card>
                <Card as="li">
                  <ContextAwareToggle eventKey="2">
                    MYLT Philosophy
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="2">
                    <p>
                      Made You Love Tennis Philosophy goes beyond winning to
                      focus on personal growth, continuous improvement, and a
                      genuine love for the sport. Through hard work,
                      self-belief, dedication, and a growth mindset, youth can
                      unlock their true potential and define their version of
                      success in tennis and life. By embracing these principles,
                      youth elevate their tennis performance and cultivate
                      essential skills that translate into success in various
                      areas. Tennis becomes a powerful platform for growth,
                      fulfillment, and positively impacting oneself and others.
                    </p>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MyltFaq;
